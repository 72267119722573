import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import React from 'react';
import { KobrosLogo } from '@fingo/lib/assets';
import { formatText } from '@fingo/lib/helpers/text-parser';
import { KobrosLandingAskForDemoButton, KobrosDownloadGuideButton, KobrosTutorial } from '../buttons';
import KobrosThemeProvider from '../../views/App/KobrosThemeProvider';
import AuthPaper from '../papers/AuthPaper';

const KobrosLandingRegisterForm = () => (
  <KobrosThemeProvider>
    <Box
      sx={
        {
          borderTop: '1px solid #7D7D7D',
        }
      }
      backgroundColor="white"
      height="95vh"
    >
      <Grid
        pt={{
          xs: 4,
          md: 10,
        }}
        container
      >
        <Grid
          item
          xs={12}
          md={6}
          alignItems="flex-start"
          justifyContent="center"
          height={{
            md: '100%',
            xs: 'auto',
          }}
          display={{
            xs: 'none',
            md: 'inherit',
          }}
        >
          <Box paddingX={4}>
            <Stack spacing={2}>
              <Typography>
                <Typography sx={{ fontSize: 40, fontWeight: 'bold' }}>koBros es tu mejor {' '}
                  <Typography sx={{ fontSize: 40, fontWeight: 'bold' }} component="span" color="kobros.main">
                    asesor de cobranza
                  </Typography>
                </Typography>
              </Typography>
              <Typography lineHeight={1.5} variant="h2">{formatText('**koBros** es una herramienta que pone a tu disposición un **analista de riesgo con inteligencia artificial** que te ayuda a tomar **mejores decisiones con tus clientes**, cobrar de forma **eficiente** y contar con los **flujos** cuando los necesitas.')}</Typography>
              <Typography lineHeight={1.5} variant="h2">¡Descubre koBros hoy!</Typography>
              <Stack
                sx={{
                  paddingTop: 1,
                }}
                direction="row"
                spacing={1.5}
              >

                <KobrosLandingAskForDemoButton
                  text="Solicitar más información"
                />
                <KobrosDownloadGuideButton
                  text="Manual de uso"
                />
              </Stack>
              <Box>
                <KobrosTutorial marginLeft={-2} />
              </Box>
            </Stack>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          justifyContent={{
            xs: 'center',
            md: 'auto',
          }}
        >
          <AuthPaper
            width={{ xs: '90%', sm: 500 }}
            mx="auto"
            py={4}
            paperSx={{
              borderRadius: '20px',
              left: '50%',
              top: '50%',
            }}
            redirectTo="app/sales/collection/collection-landing"
            logo={KobrosLogo}
          />
        </Grid>
      </Grid>
    </Box>
  </KobrosThemeProvider>
);

export default KobrosLandingRegisterForm;

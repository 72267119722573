import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import { useDynamicFingoLogo } from '@fingo/lib/hooks';
import GoogleSign from '../oauth/GoogleSign';
import RegisterForm from '../forms/RegisterForm';
import LoginForm from '../forms/LoginForm';
import AuthTabs from '../tabs/AuthTabs';
import LazyLoadImage from '../image/LazyLoadImage';
import { selectedAuthTypeVar } from '../../graphql/reactive-variables';
import { SIGN_IN } from '../../constants/auth';

const AuthPaper = ({
  logo = null,
  landingTag = null,
  redirectTo = '/app/home',
  ...props
}) => {
  const defaultLogo = useDynamicFingoLogo();
  const selectedAuthType = useReactiveVar(selectedAuthTypeVar);

  return (
    <Paper
      component={Stack}
      elevation={4}
      alignItems="center"
      justifyContent="center"
      spacing={2}
      py={{ mobile: 3, desktop: 0 }}
      px={{ desktop: 2, lg: 0 }}
      sx={{ borderRadius: 3 }}
      {...props}
    >
      <LazyLoadImage
        src={logo || defaultLogo}
        alt="Fingo"
        width={180}
        height={65}
      />
      <AuthTabs />
      <Stack
        alignItems="center"
        spacing={1.5}
        sx={{ width: { mobile: '70%', tablet: 280 } }}
        mx="auto"
      >
        {selectedAuthType === SIGN_IN ? (
          <LoginForm redirectTo={redirectTo} landingTag={landingTag} />
        ) : (
          <RegisterForm landingTag={landingTag} />
        )}
        <Divider sx={{ width: '100%', mt: '0px !important' }} />
        <GoogleSign label="Ingresar con Google" />
      </Stack>
    </Paper>
  );
};

AuthPaper.propTypes = {
  logo: PropTypes.string,
  landingTag: PropTypes.string,
  redirectTo: PropTypes.string,
};

AuthPaper.defaultProps = {
  logo: null,
  landingTag: null,
  redirectTo: '/app/home',
};

export default AuthPaper;
